<template>
  <widget :theme="theme" class="widget h-100" :class="{'dark-mode': $store.state.theme == 'dark'}">
    <div v-if="historyReady" class="sparkline" style="z-index:-10;">
      <v-sparkline :value="history" :smooth="chartType == 'trend'? 100:0" :gradient="sparklineGradient" :fill="this.$store.state.theme == 'dark'" :auto-draw="true" :type="chartType" :auto-line-width="true" line-width="2"></v-sparkline>
    </div>
    <section class="widget-contents" :class="{'dark-mode': $store.state.theme == 'dark'}" @mouseover="showPreviousAmount = true" @mouseleave="showPreviousAmount = false">
      <div class="row align-items-center">
        <div class="col">
          <div class="row align-items-center">
            <div class="col">
              <div class="value">{{formatValue(amount, format)}}</div>
              <h1>
                {{label}}
                <span style="display:block;">{{subtitle}}</span>
              </h1>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="diff" v-if="differenceInPercentage && previous" :class="{'larger': !historyReady}">
            <transition name="slide-fade" mode="out-in">
              <div key="amount" class="diff-amount" v-if="showPreviousAmount" :class="{'positive': differenceInPercentage > 0, 'negative': differenceInPercentage < 0}">{{formatValue(difference, format)}}</div>
              <div key="percent" class="diff-percent" v-else :class="{'positive': differenceInPercentage > 0, 'negative': differenceInPercentage < 0}">{{formatValue(differenceInPercentage, 'percentage')}}</div>
            </transition>
          </div>
        </div>
      </div>
    </section>

    <div class="d-flex align-items-center justify-content-between p-2 h-100 widget" @mouseover="showPreviousAmount = true" @mouseleave="showPreviousAmount = false" v-if="1==2">
      <div class="p-3">
        <div class="amount">{{formatValue(amount, format)}}</div>
        <div class="label">
          <span class="subtitle">{{subtitle}}</span>
          <div class="diff" v-if="differenceInPercentage && previous" :class="{'larger': !historyReady}">
            <transition name="slide-fade" mode="out-in">
              <div key="amount" class="diff-amount" v-if="showPreviousAmount" :class="{'positive': differenceInPercentage > 0, 'negative': differenceInPercentage < 0}">{{formatValue(difference, format)}}</div>
              <div key="percent" class="diff-percent" v-else :class="{'positive': differenceInPercentage > 0, 'negative': differenceInPercentage < 0}">{{formatValue(differenceInPercentage, 'percentage')}}</div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </widget>
</template>
<script>
import Widget from "./Widget";
import { formatValue } from "./../../formatters";
import { VSparkline } from "vuetify/lib";

export default {
  props: [
    "amount",
    "previous",
    "label",
    "subtitle",
    "format",
    "history",
    "theme",
    "chartType",
  ],
  components: {
    Widget,
    VSparkline,
  },
  data() {
    return {
      colormap: {
        blue: "#3d8bfd",
        red: "#E35D6A",
        green: "#479F76",
        purple: "#6F42C1",
        gray: "#A1A1AA",
        yellow: "#FFCD39",
      },
      revealHistory: false,
      showPreviousAmount: false,
      historyReady: false,
      historyChart: {
        data: [],
        options: {
          curveType: "function",
          backgroundColor: {
            fill: "transparent",
          },
          colors: ["#A1A1AA"],
          legend: {
            position: "none",
          },
          vAxis: {
            viewWindow: {
              max: 1,
            },
            baselineColor: "transparent",
            gridlineColor: "transparent",
            textPosition: "none",
          },
          width: 200,
          height: 100,
          lineWidth: 3,
        },
      },
    };
  },
  mounted() {
    this.buildHistoryChart();
  },
  watch: {
    history() {
      this.buildHistoryChart();
    },
  },
  computed: {
    sparklineGradient() {
      return this.$store.state.theme == "dark"
        ? ["#3D5369"]
        : [this.colormap[this.theme]];
    },
    differenceInPercentage() {
      if (!this.previous && this.previous !== 0) {
        return 0;
      }
      if (this.format == "percentage") {
        return this.amount - this.previous;
      }
      // % increase = Increase ÷ Original Number × 100
      return (
        ((this.amount - this.previous) / (this.previous ? this.previous : 1)) *
        100
      ).toFixed(1);
    },
    difference() {
      return !this.previous && this.previous !== 0
        ? 0
        : this.amount - this.previous;
    },
  },
  methods: {
    formatValue,
    buildHistoryChart() {
      this.historyChart.options.colors = [this.colormap[this.theme]];

      let builtData = [["", "value"]];
      for (let i = 0; i < 10; i++) {
        builtData.push(["", 0]);
      }
      if (this.history && this.history.length > 0) {
        builtData = [["", "value"]];
        this.history.forEach((item) => {
          builtData.push(["", item]);
        });
        this.historyChart.options.vAxis.viewWindow.max =
          Math.max(...this.history) + 4;
        this.historyReady = true;
      }
      this.historyChart.data = builtData;
      // Trigger animation to show the chart
      setTimeout(() => {
        this.revealHistory = true;
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/palette.scss";
.widget-contents {
  padding: 10px;
  color: #2c3e50;
  &.dark-mode {
    color: white;
  }
  position: relative;
  .value {
    font-size: 1.7em;
  }
  h1 {
    font-size: 0.9em;
    span {
      font-size: 0.7em;
      opacity: 0.9;
    }
  }
}
.widget {
  position: relative;
  text-shadow: 2px 2px 5px rgba(black, 0.3);
  background: white;
  color: #2c3e50;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.02) 2px,
      transparent 2px
    ),
    linear-gradient(90deg, rgba(255, 255, 255, 0.02) 2px, transparent 2px),
    linear-gradient(rgba(255, 255, 255, 0.01) 1px, transparent 1px),
    linear-gradient(90deg, rgba(255, 255, 255, 0.01) 1px, transparent 1px);
  background-size: 50px 50px, 50px 50px, 5px 5px, 5px 5px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
  min-height: 130px;
  .sparkline {
    position: absolute;
    left: -10px;
    right: -10px;
    bottom: -10px;
  }
  &.dark-mode {
    color: white;
    background: #2c3e50;
    text-shadow: 5px 5px 5px rgba(black, 0.3);
  }
}
.amount {
  font-size: 1.4em;
  font-weight: 500;
}
.label {
}
.subtitle {
  color: #a1a1aa;
  font-size: 0.8em;
}
.history {
}
.diff-amount {
  color: #999;
  font-weight: bold;
  font-size: 1.3em;
}
.diff-percent {
  color: #999;
  font-weight: bold;
  font-size: 1.3em;
}
.positive {
  color: $green-400;
}
.negative {
  color: $red-400;
}
.negative:after {
  content: " ↓";
}
.positive:after {
  content: " ↑";
}

.larger {
  font-size: 1.5em;
}
</style>