<template>
  <widget theme="blue" class="widget h-100" :class="{'dark-mode': $store.state.theme == 'dark'}">
    <div class="row p-3 pb-0 justify-content-between" v-if="dataReady">
      <div class="col-auto">
        <div class="row">
          <div class="col">
            <h3>
              {{ currentLiveVisitors }}
            </h3>
            <h6>
              <div class="d-flex align-items-center">Live Visitors <div v-show="clientConnected" class="pulsating-circle ms-2"></div></div>
            </h6>
            <div class="col p-0" style="font-size:0.9em;">
                <router-link style="color:white;text-decoration:none;" to="visitors">{{ onlineToday }} Visitors Today</router-link>
            </div>
            <div class="col p-0">
              <div class="my-1 d-flex align-items-center" style="font-size:0.7em;">
                <i class="fa fa-mobile"></i>
                  <div class="progress w-100 mx-1">
                    <div class="progress-bar mobile" role="progressbar" :style="'width:'+mobileVisitorsToday+'%;font-size:0.8em;'" >{{mobileVisitorsToday}}%</div>
                    <div class="progress-bar desktop" role="progressbar" :style="'width:'+desktopVisitorsToday+'%'"></div>
                  </div>
                <i class="fa fa-display"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-10 p-0">
        <div class="row">
          <div class="col-12 p-0">
            <div class="chart p-0">
              <ApexChart :key="chartKey" type="bar" height="120" :options="chartOptions" :series="series" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" v-else>
      <div class="col-auto mt-5">
        <div class="spinner-border" style="color: #ffffff44;" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </widget>
</template>
<script>
import Widget from "./Widget";
import axios from "./../../http.js";
import { formatValue } from "./../../formatters";
import ApexChart from "vue-apexcharts";
import MqttClient from "./../../mqtt.js";
import EventBus from "./../..//bus.js";

export default {
  props: [
    "amount",
    "previous",
    "label",
    "subtitle",
    "format",
    "history",
    "theme",
    "chartType",
  ],
  components: {
    Widget,
    ApexChart,
  },
  data() {
    return {
      currentLiveVisitors: null,
      onlineToday: 0,
      dataReady: false,
      timer: null,
      latestCall: null,
      mobileVisitorsToday: 0,
      desktopVisitorsToday: 0,

      chartKey: 1,
      series: [{
        name: 'visitors',
        data: []
      }],
      chartOptions: {
        chart: {
          height: 150,
          type: 'bar',
          toolbar: {
            show: false,
          },
          zeroIgnored: false,
          animations: {
            enabled: true,
          },
        },
        stroke: {
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            colors: {
              ranges: [
                {
                  from: 0,
                  to: 1,
                  color: '#5e87ad',
                },
                {
                  from: 1,
                  to: 10000,
                  color: '#40a0fc',
                },
              ],
            },
          }
        },
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return parseInt(val).toString();
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
          }
        },
        grid: {
          show: false,
        },
        tooltip: {
          theme: 'dark',
          y: {
            formatter: function (val) {
              return parseInt(val).toString();
            },
          },
        },
        xaxis: {
          categories: [],
        
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          
        },
        yaxis: {
          max: 1,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          }
        },
      },
    };
  },
  mounted() {
    this.fetchData();
    this.timer = setInterval(() => {
      //  check if the minute has changed
      let date = new Date();
      if (date.getSeconds() <= 4) {
        this.fetchData();
      }
    }, 5000)

    let topic = "globus/vaveproxy/events/#";
    MqttClient.subscribe(topic);
    EventBus.$on(topic, this.fetchData);
  },
  computed: {
    clientConnected() {
      return MqttClient.getClient().connected;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    EventBus.$off("globus/vaveproxy/events/#", this.fetchData);
  },
  methods: {
    formatValue,
    fetchData() {
      // Add a cooldown system that prevents a call if the previous call was less than 2 seconds ago
      if (this.latestCall != null && (new Date() - this.latestCall) < 2000) {
        return;
      }
      this.latestCall = new Date();

      axios.get("/api/admin/dashboard/stats/live-customers").then((response) => {
        let chartData = [];
        let chartLabels = [];
        let maxValue = 3;

        for (const [key, value] of Object.entries(response.data.data)) {
          chartLabels.push(key);
          chartData.push(Math.max(0.5, value));
          maxValue = Math.max(maxValue, value);
        }

        this.series[0].data = chartData.reverse();
        this.chartOptions.xaxis.categories = chartLabels.reverse();
        this.chartOptions.yaxis.max = maxValue;
        
        this.currentLiveVisitors = response.data.currently_online;
        this.onlineToday = response.data.online_today;

        let mobileVisitors = response.data.user_agents_today.mobile;
        let desktopVisitors = response.data.user_agents_today.desktop;
        this.mobileVisitorsToday = Math.floor((mobileVisitors / (mobileVisitors + desktopVisitors)) * 100);
        this.mobileVisitorsToday = this.mobileVisitorsToday ? this.mobileVisitorsToday : 0;
        this.desktopVisitorsToday = 100 - this.mobileVisitorsToday;

        setTimeout(() => {
          this.chartOptions.chart.animations.enabled = false;
        }, 2000);

          this.dataReady = true;
          this.chartKey++;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/palette.scss";
.widget {
  position: relative;
  text-shadow: 2px 2px 5px rgba(black, 0.3);
  background: white;
  color: #2c3e50;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.02) 2px,
      transparent 2px
    ),
    linear-gradient(90deg, rgba(255, 255, 255, 0.02) 2px, transparent 2px),
    linear-gradient(rgba(255, 255, 255, 0.01) 1px, transparent 1px),
    linear-gradient(90deg, rgba(255, 255, 255, 0.01) 1px, transparent 1px);
  background-size: 50px 50px, 50px 50px, 5px 5px, 5px 5px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
  min-height: 130px;
  &.dark-mode {
    color: white;
    background: #2c3e50;
    text-shadow: 5px 5px 5px rgba(black, 0.3);
  }
}
.subtitle {
  color: #a1a1aa;
  font-size: 0.8em;
}
.chart{
  position: relative;
  bottom: -29px;
}

.pulsating-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #2ecc71;
  animation: pulsate 2s infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.progress {
  height: 12px;
  .progress-bar {
    &.mobile {
      background:#40a0fc;
    }
    &.desktop {
      background:#5e87ad;
    }
  }
}

</style>