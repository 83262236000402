<template>
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-9">
        <div class="row align-items-center">
          <div class="col-auto">
            <h1 class="mb-4 mt-4 belize">
              <i class="fa-solid fa-chart-line"></i> MStore Stats Dashboard
            </h1>
          </div>
          <div class="col"></div>
          <div class="col-auto">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="bars-checkbox" @change="changeChartsType()" :checked="$store.state.chartType == 'bar'" />
              <label class="form-check-label" for="bars-checkbox">Bars</label>
            </div>
          </div>
          <div class="col-auto">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="theme-checkbox" @change="changeTheme()" :checked="$store.state.theme == 'dark'" />
              <label class="form-check-label" for="theme-checkbox">
                <span v-if="$store.state.theme == 'dark'">🌙</span>
                <span v-if="$store.state.theme == 'light'">☀️</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="ordersStatsReady && displayContents('orders')" class="dashboard row justify-content-center">
      <div class="col-9" style="position:relative;">
        <div class="row align-items-center mb-1">
          <div class="col-11">
            <LiveVisitors :chartType="chartType" />
          </div>
        </div> 
        <div class="row align-items-center curly-brace">
          <div class="col-11">
            <div class="row">
              <div class="col pe-0">
                <card label="Orders" :chartType="chartType" :amount="ordersStats.today.count.processing" :previous="ordersStats.yesterday.count.processing" theme="blue" :history="ordersHistoriesReady ? ordersHistories.count : []" />
              </div>
              <div class="col ps-0 pe-0">
                <card label="Orders Value" :chartType="chartType" :amount="ordersStats.today.amount.processing" :previous="ordersStats.yesterday.amount.processing" format="money" theme="blue" :history="ordersHistoriesReady ? ordersHistories.amount.per_day : []" />
              </div>
              <div class="col ps-0">
                <card label="Total Items Sold" :chartType="chartType" :amount="ordersStats.today.items.total_items_sold" :previous="ordersStats.yesterday.items.total_items_sold" theme="blue" :history="ordersHistoriesReady ? ordersHistories.items.total_items_sold : []" />
              </div>
            </div>
            <div class="row mt-1">
              <div class="col pe-0">
                <card
                  label="Biggest Order"
                  subtitle="(by value)"
                  :chartType="chartType"
                  :amount="ordersStats.today.amount.biggest_order_amount"
                  :previous="ordersStats.yesterday.amount.biggest_order_amount"
                  format="money"
                  theme="green"
                  :history="ordersHistoriesReady ? ordersHistories.amount.biggest_orders : []"
                />
              </div>
              <div class="col ps-0 pe-0">
                <card label="Avg Order Value" :chartType="chartType" :amount="ordersStats.today.amount.average_order_amount" :previous="ordersStats.yesterday.amount.average_order_amount" format="money" theme="red" />
              </div>
              <div class="col ps-0">
                <card label="Avg Order Items" :chartType="chartType" :amount="ordersStats.today.items.average_order_items" :previous="ordersStats.yesterday.items.average_order_items" format="decimal" theme="red" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6 pe-0">
                <chart :data="todayOrdersAmount" theme="green" />
              </div>
              <div class="col-6 ps-0">
                <chart :data="todayCustomers" theme="purple" />
              </div>
            </div>
          </div>
          <div class="col d-flex flex-row align-items-center justify-content-end px-0">
            <div class="title">TODAY</div>
          </div>
        </div>

        <div class="row align-items-center curly-brace">
          <div class="col-11">
            <div class="row">
              <div class="col pe-0">
                <card label="Orders" :chartType="chartType" :amount="ordersStats.yesterday.count.processing" :previous="ordersStats.daybeforeyesterday.count.processing" theme="blue" :history="ordersHistoriesReady ? ordersHistories.count : []" />
              </div>
              <div class="col ps-0 pe-0">
                <card label="Orders Value" :chartType="chartType" :amount="ordersStats.yesterday.amount.processing" :previous="ordersStats.daybeforeyesterday.amount.processing" format="money" theme="blue" :history="ordersHistoriesReady ? ordersHistories.amount.per_day : []" />
              </div>
              <div class="col ps-0">
                <card label="Total Items Sold" :chartType="chartType" :amount="ordersStats.yesterday.items.total_items_sold" :previous="ordersStats.daybeforeyesterday.items.total_items_sold" theme="blue" :history="ordersHistoriesReady ? ordersHistories.items.total_items_sold : []" />
              </div>
            </div>
            <div class="row mt-1">
              <div class="col pe-0">
                <card
                  label="Biggest Order"
                  subtitle="(by value)"
                  :chartType="chartType"
                  :amount="ordersStats.yesterday.amount.biggest_order_amount"
                  :previous="ordersStats.daybeforeyesterday.amount.biggest_order_amount"
                  format="money"
                  theme="green"
                  :history="ordersHistoriesReady ? ordersHistories.amount.biggest_orders : []"
                />
              </div>
              <div class="col ps-0 pe-0">
                <card label="Avg Order Value" :chartType="chartType" :amount="ordersStats.yesterday.amount.average_order_amount" :previous="ordersStats.daybeforeyesterday.amount.average_order_amount" format="money" theme="red" />
              </div>
              <div class="col ps-0">
                <card label="Avg Order Items" :chartType="chartType" :amount="ordersStats.yesterday.items.average_order_items" :previous="ordersStats.daybeforeyesterday.items.average_order_items" format="decimal" theme="red" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6 pe-0">
                <chart :data="yesterdayOrdersAmount" theme="green" />
              </div>
              <div class="col-6 ps-0">
                <chart :data="yesterdayCustomers" theme="purple" />
              </div>
            </div>
          </div>
          <div class="col d-flex flex-row align-items-center justify-content-end px-0">
            <div class="title">YESTERDAY</div>
          </div>
        </div>

        <div class="row justify-content-center curly-brace">
          <div class="col-11">
            <div class="row justify-content-center">
              <div class="col pe-0">
                <card label="Orders" :chartType="chartType" :amount="ordersStats.this_week.count.processing" :previous="ordersStats.past_week.count.processing" theme="blue" />
              </div>
              <div class="col pe-0 ps-0">
                <card label="Orders Value" :chartType="chartType" :amount="ordersStats.this_week.amount.processing" :previous="ordersStats.past_week.amount.processing" format="money" theme="blue" />
              </div>
              <div class="col ps-0">
                <card label="Total Items Sold" :chartType="chartType" :amount="ordersStats.this_week.items.total_items_sold" :previous="ordersStats.past_week.items.total_items_sold" theme="blue" />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col pe-0">
                <card label="Biggest Order" subtitle="(by value)" :chartType="chartType" :amount="ordersStats.this_week.amount.biggest_order_amount" :previous="ordersStats.past_week.amount.biggest_order_amount" format="money" theme="green" />
              </div>
              <div class="col pe-0 ps-0">
                <card label="Avg Order Value" :chartType="chartType" :amount="ordersStats.this_week.amount.average_order_amount" :previous="ordersStats.past_week.amount.average_order_amount" format="money" theme="red" />
              </div>
              <div class="col ps-0">
                <card label="Avg Order Items" :chartType="chartType" :amount="ordersStats.this_week.items.average_order_items" :previous="ordersStats.past_week.items.average_order_items" format="decimal" theme="red" />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-6 pe-0">
                <chart :data="thisWeekOrdersAmount" theme="green" />
              </div>
              <div class="col-6 ps-0">
                <chart :data="thisWeekCustomers" theme="purple" />
              </div>
            </div>
          </div>
          <div class="col d-flex flex-row align-items-center justify-content-end px-0">
            <div class="title subtitle">compared to past week ({{formatValue(ordersStats.past_week.from, 'shortDate')}} to {{formatValue(ordersStats.past_week.to, 'shortDate')}})</div>
            <div class="title">
              THIS WEEK
              <span class="subtitle">({{formatValue(ordersStats.this_week.from, 'shortDate')}} to {{formatValue(ordersStats.this_week.to, 'shortDate')}})</span>
            </div>
          </div>
        </div>

        <div class="row justify-content-center curly-brace">
          <div class="col-11">
            <div class="row">
              <div class="col pe-0">
                <card label="Orders" :chartType="chartType" :amount="ordersStats.this_month.count.processing" :previous="ordersStats.past_month.count.processing" theme="blue" />
              </div>
              <div class="col pe-0 ps-0">
                <card label="Orders Value" :chartType="chartType" :amount="ordersStats.this_month.amount.processing" :previous="ordersStats.past_month.amount.processing" format="money" theme="blue" />
              </div>
              <div class="col ps-0">
                <card label="Total Items Sold" :chartType="chartType" :amount="ordersStats.this_month.items.total_items_sold" :previous="ordersStats.past_month.items.total_items_sold" theme="blue" />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col pe-0">
                <card label="Biggest Order" subtitle="(by value)" :chartType="chartType" :amount="ordersStats.this_month.amount.biggest_order_amount" :previous="ordersStats.past_month.amount.biggest_order_amount" format="money" theme="green" />
              </div>
              <div class="col pe-0 ps-0">
                <card label="Avg Order Value" :chartType="chartType" :amount="ordersStats.this_month.amount.average_order_amount" :previous="ordersStats.past_month.amount.average_order_amount" format="money" theme="red" />
              </div>
              <div class="col ps-0">
                <card label="Avg Order Items" :chartType="chartType" :amount="ordersStats.this_month.items.average_order_items" :previous="ordersStats.past_month.items.average_order_items" format="decimal" theme="red" />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-6 pe-0">
                <chart :data="thisMonthOrdersAmount" theme="green" />
              </div>
              <div class="col-6 ps-0">
                <chart :data="thisMonthCustomers" theme="purple" />
              </div>
            </div>
          </div>
          <div class="col d-flex flex-row align-items-center justify-content-end px-0">
            <div class="title subtitle">compared to past month ({{formatValue(ordersStats.past_month.from, 'shortDate')}} to {{formatValue(ordersStats.past_month.to, 'shortDate')}})</div>
            <div class="title">
              THIS MONTH
              <span class="subtitle">({{formatValue(ordersStats.this_month.from, 'shortDate')}} to {{formatValue(ordersStats.this_month.to, 'shortDate')}})</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="col">
        <div class="mt-4">
          <div class="loading-placeholder">
            <div class="spinner-border spinner-border-sm ms-2 big-spinner light-spinner" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "../http.js";
import Chart from "./dashboard/Chart.vue";
import Card from "./dashboard/Card.vue";
import LiveVisitors from "./dashboard/LiveVisitors.vue";
// import List from "./dashboard/List.vue";
import { formatValue } from "./../formatters";

export default {
  data() {
    return {
      showBars: false,
      tabs: [
        {
          name: "orders",
          displayName: "Orders",
        },
        {
          name: "customers",
          displayName: "Customers",
        },
      ],
      activeTabName: "orders",

      // Orders Charts
      todayOrdersAmount: { data: [], options: {} },
      yesterdayOrdersAmount: { data: [], options: {} },
      thisWeekOrdersAmount: { data: [], options: {} },
      thisMonthOrdersAmount: { data: [], options: {} },

      // Customers Charts
      todayCustomers: { data: [], options: {} },
      yesterdayCustomers: { data: [], options: {} },
      thisWeekCustomers: { data: [], options: {} },
      thisMonthCustomers: { data: [], options: {} },
      totalCustomers: { data: [], options: {} },

      // Orders
      ordersStats: {},
      ordersStatsReady: false,
      ordersHistories: {},
      ordersHistoriesReady: false,

      // Customers
      customersStats: {},
      customersStatsReady: false,
      ordersPerCustomer: { data: [], options: {} },
    };
  },
  components: {
    LiveVisitors,
    Chart,
    Card,
    // List,
  },
  mounted() {
    this.ordersStatsReady = false;
    this.init();
    setInterval(() => {
      this.init();
    }, 60000);
    this.activeTabName = this.tabs[0].name;
  },
  computed: {
    chartType() {
      return this.$store.state.chartType;
    },
    currentMonth() {
      return moment().format("MMMM");
    },
  },
  methods: {
    changeTheme() {
      this.$store.commit("toggleTheme");
    },
    changeChartsType() {
      this.$store.commit("toggleBarChart");
    },
    init() {
      this.getOrdersStats();
      this.getHistories();
      this.getCustomersStats();
    },
    formatValue,
    setActiveTabName(name) {
      this.activeTabName = name;
    },
    displayContents(name) {
      return this.activeTabName === name;
    },
    getCustomersStats() {
      this.customersStatsReady = false;
      axios.get("/api/admin/dashboard/stats/customers").then((response) => {
        if (response.data) {
          this.customersStats = response.data;
          this.buildCustomersChart(
            {
              registered: this.customersStats.registered,
              guests: this.customersStats.guests,
            },
            this.totalCustomers,
            "Customers"
          );
          this.buildOrdersChart(
            {
              zero: this.customersStats.zero_order,
              one: this.customersStats.single_order,
              more: this.customersStats.multiple_orders,
            },
            this.ordersPerCustomer,
            "Orders/cust."
          );
          this.customersStatsReady = true;
        }
      });
    },
    getOrdersStats() {
      axios.get("/api/admin/dashboard/stats/orders").then((response) => {
        if (response.data) {
          this.ordersStats = response.data;
          // Orders Amount
          this.buildAmountChart(
            this.ordersStats.today.amount,
            this.todayOrdersAmount,
            "Total Orders"
          );
          this.buildAmountChart(
            this.ordersStats.this_week.amount,
            this.thisWeekOrdersAmount,
            "Total Orders"
          );
          this.buildAmountChart(
            this.ordersStats.yesterday.amount,
            this.yesterdayOrdersAmount,
            "Total Orders"
          );
          this.buildAmountChart(
            this.ordersStats.this_month.amount,
            this.thisMonthOrdersAmount,
            "Total Orders"
          );
          // Customers
          // let customers = this.ordersStats.today.customers;
          this.buildCustomersChart(
            this.ordersStats.today.customers,
            this.todayCustomers,
            "Customers"
          );
          this.buildCustomersChart(
            this.ordersStats.yesterday.customers,
            this.yesterdayCustomers,
            "Customers"
          );
          // customers = this.ordersStats.this_week.customers;
          this.buildCustomersChart(
            this.ordersStats.this_week.customers,
            this.thisWeekCustomers,
            "Customers"
          );
          // customers = this.ordersStats.this_month.customers;
          this.buildCustomersChart(
            this.ordersStats.this_month.customers,
            this.thisMonthCustomers,
            "Customers"
          );
          this.ordersStatsReady = true;
        }
      });
    },
    getHistories() {
      axios
        .get("/api/admin/dashboard/stats/orders/history")
        .then((response) => {
          if (response.data) {
            this.ordersHistories = response.data;
            this.ordersHistoriesReady = true;
          }
        });
    },
    buildAmountChart(data, chart, title) {
      chart.data = [
        ["£ Processing/Completed", "£ Awaiting Payment", "£ Pending"],
        [
          data.processing / 100,
          data.awaiting_payment_confirmation / 100,
          data.pending / 100,
        ],
      ];
      chart.options = {
        title: title,
        bars: "horizontal",
        width: 500,
        vAxis: { maxValue: 10 },
        chartArea: { width: "50%" },
        colors: ["#0D6EFD", "#FD7E14", "#A1A1AA"],
        animation: {
          startup: true,
          duration: 500,
          easing: "linear",
        },
      };
    },
    buildOrdersChart(data, chart, title) {
      chart.data = [
        ["None", "Single", "Multiple"],
        [data.zero, data.one, data.more],
      ];
      chart.options = {
        title: title,
        bars: "horizontal",
        width: 500,
        vAxis: { maxValue: 10 },
        chartArea: { width: "50%" },
        colors: ["#A1A1AA", "#0D6EFD", "#479F76"],
        animation: {
          startup: true,
          duration: 500,
          easing: "linear",
        },
      };
    },
    buildCustomersChart(data, chart, title) {
      chart.data = [
        ["Registered", "Guests"],
        [data.registered, data.guests],
      ];
      chart.options = {
        title: title,
        bars: "horizontal",
        width: 500,
        vAxis: { maxValue: 10 },
        chartArea: { width: "50%" },
        colors: ["#6F42C1", "#A1A1AA"],
        animation: {
          startup: true,
          duration: 500,
          easing: "linear",
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #3498db;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 2em;

  .subtitle {
    font-size: 0.5em;
    color: #2980b9;
    opacity: 0.8;
  }
}
.subtitle {
  color: #7f8c8d;
  font-size: 0.8em;
}

.curly-brace {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="lightgrey" d="M231.9 256C197.1 278.7 176 317.2 176 359.5V408c0 30.88-25.12 56-56 56h-48C67.58 464 64 467.6 64 472S67.58 480 72 480h48c39.7 0 72-32.3 72-72v-48.45c0-41.08 22.83-78.02 59.58-96.39C254.3 261.8 256 259 256 256s-1.719-5.797-4.422-7.156C214.8 230.5 192 193.5 192 152.5V104C192 64.3 159.7 32 120 32h-48C67.58 32 64 35.58 64 40S67.58 48 72 48h48c30.88 0 56 25.12 56 56v48.45C176 194.8 197.1 233.3 231.9 256z" /></svg>');
  background-repeat: no-repeat;
  background-position: calc(100% - 50px) center;
  background-size: contain;

  padding-bottom: 10px;
  border-bottom: 2px dashed #bdc3c7;
  margin-bottom: 10px;

  &:last-of-type {
    border-bottom: none;
  }
}

.belize {
  color: #2980b9;
}
.form-check-input {
  box-shadow: none !important;
}

.sparkline {
  path,
  rect {
    filter: drop-shadow(0px 0px 10px rgba(darken(#2c3e50, 10%), 0.8));
  }
}
</style>