<template>
  <div class="item" :class="theme">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: ["theme"],
};
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/palette.scss";

.item {
  margin-right: 10px;
  background: white;
  border-radius: 3px;
  filter: drop-shadow(0px 5px 1px darken(#dde7ed,10%));
  overflow: hidden;
}

.blue {
  border-top: 5px solid $blue-400;
}
.green {
  border-top: 5px solid $green-400;
}
.yellow {
  border-top: 5px solid $yellow-400;
}
.cyan {
  border-top: 5px solid $cyan-400;
}
.gray {
  border-top: 5px solid $gray-400;
}
.red {
  border-top: 5px solid $red-400;
}
.purple {
  border-top: 5px solid $purple-400;
}
</style>