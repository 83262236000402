<template>
  <widget :theme="theme" class="widget">
    <section class="widget-contents py-3 px-2" :class="{'dark-mode': $store.state.theme == 'dark'}">
      <div class="row">
        <div class="col-1">
          <h1>{{data.options.title}}</h1>
        </div>
        <div class="col-11">
          <apexcharts type="donut" :options="chartOptions" :series="series" :height="height"></apexcharts>
        </div>
      </div>
    </section>
  </widget>
</template>
<script>
import Widget from "./Widget";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    Widget,
    apexcharts: VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      required: false,
    },
    height: {
      type: String,
      required: false,
      default: "120",
    },
    colors: {
      type: Array,
      required: false,
      default: () => [
        "#775dd0",
        "#f94560",
        "#3be396",
        "#fbb019",
        "#3498db",
      ],
    },
  },
  data() {
    return {
      series: this.data.data[1],
    };
  },
  computed: {
    chartOptions() {
      return {
        legend: {
          show: true,
          labels: {
            colors: this.$store.state.theme == "dark" ? "#fff" : "#000",
          },
        },
        labels: this.data.data[0],
        colors: this.colors,
        chart: {
          type: "donut",
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-contents {
  color: #2c3e50;
  background: white;
  text-shadow: 2px 2px 5px rgba(black, 0.3);
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.02) 2px,
      transparent 2px
    ),
    linear-gradient(90deg, rgba(255, 255, 255, 0.02) 2px, transparent 2px),
    linear-gradient(rgba(255, 255, 255, 0.01) 1px, transparent 1px),
    linear-gradient(90deg, rgba(255, 255, 255, 0.01) 1px, transparent 1px);
  background-size: 50px 50px, 50px 50px, 5px 5px, 5px 5px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
  &.dark-mode {
    color: white;
    background: #2c3e50;
    text-shadow: 0px 0px 5px rgba(black, 0.3);
  }
  h1 {
    font-size: 1.3em;
    text-align: right;
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
}
</style>
